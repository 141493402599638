import { Content } from "@/models/content";
import { ContentManager } from "@/services/contentManager";

export const day5: Content[] = [
    {
        id: "6f6060ef-4843-4bad-a89a-8b403f88d59d",
        text: [
            "Hi <nick name>! Welcome back to wMammogram project! Today, we’d like to talk about barriers."
        ],
        textStyle: [
            ["text-h5", "text-center"]
        ],
        pinkie: {
            src: require("@/assets/pinkie/hello.png"),
            animation: "pinkie-hello",
            left: true
        }
    },
    {
        id: "4b953f0b-8a44-4d63-b6c3-46e4f77a1d5f",
        text: [
            "Some American Indian women have said that they do not need to get a mammogram because their life is already determined and there is nothing that they can do to control their life."
        ],
        question: {
            question: "Do you agree with this?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 1,
            text: [
                [
                    "You’re right. Sometimes our life can be out of our control."
                ],
                [
                    "We do agree with your opinion. We may not control your death, but we can control the quality of your life!"
                ]
            ],
            common: [
                "However, this view does not justify that you should not get a mammogram.",
                "Getting accurate screening tests will help you find a problem early enough so that you can resolve a problem!"
            ]
        }
    },
    {
        id: "68750a32-a393-4b6c-a562-14e4b0b5f679",
        text: [
            "We talked about 3 different ways to look for breast cancer. However, only a mammogram can accurately (up to 80%) screen you for breast cancer.",
            "Medical guidelines recommend that every woman conduct monthly self-breast exams and receive clinical breast exams yearly because knowing your own body is important for your health. These two methods are not as accurate as mammograms, but they are still beneficial.",
            "As you can see, all three methods are an important part of taking care of yourself. Mammograms will detect breast cancer early so that you can treat it early."
        ],
        question: {
            question: "Do you feel afraid to get a mammogram because you may find cancer, and if that is the case, want to delay your finding as much as possible?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 1,
            text: [
                [
                    "Many women have mentioned that they became really anxious right before hearing their screening results.",
                    "However, once you start getting a mammogram and do it regularly, it shouldn’t be that way."
                ],
                [
                    "We’re glad that you don’t think that way!"
                ]
            ]
        }
    },
    {
        id: "9381e319-6df5-4e08-a7c5-06c7bcc44700",
        text: [
            "Look at the chart, as you may see, you will have higher survivor rate if you find it early!",
            "That is why you shouldn’t delay your mammogram because of fear."
        ],
        pinkie: {
            src: require("@/assets/srate.png"),
            left: true
        }
    },
    {
        id: "dd048a2a-72bb-46b3-ac6c-aecc333b8805",
        question: {
            question: "Here are what some American Indian women have said:\n“Getting a mammogram is disruptive to the welfare of the family.”\n\"I go to hospital only when I am really sick since I have to be a caretaker of my family.\"\nIs this your story, too?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 1,
            text: [
                [
                    "It is true that women get to have many responsibilities as a caretaker in their family.",
                ],
                [
                    "Good! Those statements are partially true, but women’s health is also important. Right?! 😃"
                ]
            ],
            common: [
                "You can take care of your family only when you’re healthy.",
                "Your health status is not just only for yourself but also for your family! 🏋️‍♀️"
            ]
        },
    },
    {
        id: "9d5dc05c-6f97-4030-a6da-e99b12143442",
        text: [
            "We showed you a video of mammogram procedures on Day 2.",
            "Most examiners will go over the same procedures, minimizing your breast exposure in a polite manner.",
            "But still feeling uncomfortable?",
            "In this video, a technician of the Johns Hopkins Breast Imaging Center talks in detail about the procedure of receiving a mammogram there."
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/What to Expect During Your First Mammogram.mp4")
            }
        }
    },
    {
        id: "67b428d7-ab3e-45d9-8ad2-60aef6317dc1",
        text: [
            "Here’s good news for you!",
            "There are only female technicians throughout the Wagner, Yankton, and Sioux Falls area!"
        ]
    },
    {
        id: "e68ea58e-78ee-440e-a589-a7723ad77a90",
        text: [
            "You watched several interview videos today. We hope they were helpful.",
            "If you have any other questions or concerns, don’t hesitate to contact our health navigators by calling tel:605-202-1418 605-202-1418.",
            "Did you enjoy today’s program?",
            "You will receive a notification later to inform you how many pink ribbons you have received today."
        ]
    }
];

export const manager = new ContentManager(day5);

export default day5;